import * as React from 'react';
import { useState } from "react";
import { Box, Tabs, Tab, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import PageLayout from "../layout/PageLayout";
import TeamsTable from './TeamsTable';
import DataIntegrations from './DataIntegrations';
import Audit from './Audit';
var tabStyles = {
    color: 'rgba(0, 0, 0, 0.87)',
    '&.Mui-selected': {
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: 'rgba(107,33,168,.1)',
    },
    '&:hover': {
        backgroundColor: 'rgba(107,33,168,.1)',
    },
};
export default function Settings(_a) {
    var attributes = _a.attributes, navigate = _a.navigate, location = _a.location, STUDIES = _a.STUDIES, DASHBOARDS = _a.DASHBOARDS, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, getAccessibleDashboards = _a.getAccessibleDashboards, clientLogo = _a.clientLogo, clientName = _a.clientName, envName = _a.envName, fetchAuthSession = _a.fetchAuthSession, INTEGRATIONS_DASHBOARDS = _a.INTEGRATIONS_DASHBOARDS, INTEGRATIONS_QUESTIONS = _a.INTEGRATIONS_QUESTIONS, successToast = _a.successToast, errorToast = _a.errorToast, ROLES = _a.ROLES;
    var _b = useState(0), value = _b[0], setValue = _b[1];
    var isAdmin = attributes.role === 'admin';
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    return (React.createElement(PageLayout, { attributes: attributes, fetchAuthSession: fetchAuthSession, navigate: navigate, location: location, STUDIES: STUDIES, DASHBOARDS: DASHBOARDS, getAccessibleDashboards: getAccessibleDashboards, clientLogo: clientLogo, clientName: clientName, envName: envName }, isAdmin ? (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: { borderBottom: 1, borderColor: 'divider' } },
            React.createElement(Tabs, { value: value, onChange: handleChange, "aria-label": "settings tabs", TabIndicatorProps: {
                    style: {
                        backgroundColor: 'rgba(99,38,161,1)',
                    },
                } },
                React.createElement(Tab, { label: "Teams", sx: tabStyles }),
                React.createElement(Tab, { label: "Data Integrations", sx: tabStyles, disableRipple: true }),
                React.createElement(Tab, { label: "Audit", sx: tabStyles, disableRipple: true }))),
        React.createElement(Box, { sx: { p: 2 } },
            value === 0 && (React.createElement(TeamsTable, { attributes: attributes, clientName: clientName, envName: envName, fetchAuthSession: fetchAuthSession, successToast: successToast, errorToast: errorToast, ROLES: ROLES })),
            value === 1 && (React.createElement(DataIntegrations, { INTEGRATIONS_QUESTIONS: INTEGRATIONS_QUESTIONS, INTEGRATIONS_DASHBOARDS: INTEGRATIONS_DASHBOARDS, DASHBOARDS: DASHBOARDS, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS, attributes: attributes, clientName: clientName, envName: envName })),
            value === 2 && (React.createElement(Audit, { clientName: clientName, envName: envName, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS, DASHBOARDS: DASHBOARDS, attributes: attributes }))))) : (React.createElement(Box, { sx: { p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' } },
        React.createElement(WarningIcon, { sx: { color: 'warning.main', marginRight: 1, fontSize: '6rem' } }),
        React.createElement(Typography, { variant: "h5", textAlign: "center" },
            "Your ",
            attributes.role,
            " role is not authorized to access this page. Please contact your admin if you believe this is an error.")))));
}
