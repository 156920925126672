import { DASHBOARDS, ROLES } from "../utils/consts";

/**
 * Get the list of dashboards accessible to each user based on role
 * @param role 
 * @returns list of dashboards accessible to each user based on role
 */
const getAccessibleDashboards = (role: String) => {
    const dashboards = Object.keys(DASHBOARDS);
    
    switch (role) {
        case ROLES.EXTERNAL:
			return dashboards;
        case ROLES.OPERATIONS:
			return dashboards;
        case ROLES.LEADERSHIP:
            return dashboards;
        case ROLES.MEDICAL:
            return dashboards;
        case ROLES.ADMIN:
            return dashboards;
        default:
            return dashboards;
    }
}

export { getAccessibleDashboards };