import { faCircleCheck, faTableList, faChartGantt, faChartSimple, faWindowMaximize, faHospitalUser, faCircleExclamation, faChartPie, faMagnifyingGlass, faPills } from "@fortawesome/free-solid-svg-icons";

export const MOBILE_WIDTH = 600; // px

// update study names for each client
export const STUDIES = [
	'201 Study'
]

// update dashboard ids for each study
export const DASHBOARDS = {
    main_updates: {name: "Main Updates", dashboard_id: 430, icon: faChartSimple, study: "201 Study", fullName: '201 Main Updates' },
	recruitment_report: {name: "Recruitment Report", dashboard_id: 530, icon: faTableList, study: "201 Study", fullName: '201 Recruitment Report' },
	study_website: {name: "Study Website", dashboard_id: 463, icon: faWindowMaximize, study: "201 Study", fullName: '201 Study Website' },
	site_monitoring: {name: "Site Monitoring", dashboard_id: 431, icon: faHospitalUser, study: "201 Study", fullName: '201 Site Monitoring' },
	requiring_attention: {name: "Requiring Attention", dashboard_id: 433, icon: faCircleExclamation, study: "201 Study", fullName: '201 Requiring Attention' },
	prescreen_failures: {name: "Pre-Screen Failures", dashboard_id: 432, icon: faChartPie, study: "201 Study", fullName: '201 Pre-Screen Failures' },
	prescreen_passed: {name: "Pre-Screen Passed", dashboard_id: 435, icon: faCircleCheck, study: "201 Study", fullName: '201 Pre-Screen Passed' },
	schedule_of_assessments: {name: "Visit Tracker", dashboard_id: 960, icon: faChartGantt, study: "201 Study", fullName: '201 Visit Tracker' },
	data_review: {name: "Data Review", dashboard_id: 892, icon: faMagnifyingGlass, study: "201 Study", fullName: '201 Data Review' },
	drug_supply: {name: "Drug Supply", dashboard_id: 859, icon: faPills, study: "201 Study", fullName: '201 Drug Supply' },
};

// update roles for each client
export const ROLES = {
    EXTERNAL: 'external',
    OPERATIONS: 'operations',
    LEADERSHIP: 'leadership',
    MEDICAL: 'medical',
    ADMIN: 'admin',
};

// need to update with integration sync times for each study and data source
export const INTEGRATIONS_DASHBOARDS = {
    Medidata: {
        name: "Medidata", 
        icon: '/medidata.png',
        studies: [
            {
                name: "201 Study",
                dashboard_id: 2344,
            },
        ]
    }
}

export const INTEGRATIONS_QUESTIONS = {
    Medidata: {
        name: "Medidata", 
        icon: '/medidata.png',
        studies: [
            {
                name: "201 Study",
                question_id: 5258,
            },
        ]
    }
}

export const SPECIAL_DASHBOARDS = {
  user_metrics: { name: "User Metrics", dashboard_id: 799 },
  content_activity: { name: "Content Activity", dashboard_id: 2278 }
};